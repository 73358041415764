import type { BlockData } from '../../templates/Block'
import { theme } from '../../../styles'

export function AuthorArticle({
  author,
  'data-module': dataModule,
}: BlockData<'article-author'>): JSX.Element {
  return (
    <footer data-module={dataModule}>
      <hr className="AuthorArticle__Separator" aria-hidden="true" />
      <div className="AuthorArticle__Content">{author || 'La rédaction TF1 Info'}</div>

      <style jsx>{`
        .AuthorArticle__Separator {
          margin: 40px ${theme.block.marginLR}px 0 ${theme.block.marginLR}px;
          border: none;
          border-top: 1px solid var(--color-lightBlue);
        }

        .AuthorArticle__Content {
          margin: 30px ${theme.block.marginLR}px 70px;
          color: var(--color-deepBlue);
          font-weight: 700;
          font-size: 14px;
        }
      `}</style>
    </footer>
  )
}
