import type { ArticleHeaderCredit } from '@etf1-interne/tf1info_types_news/generics/article'
import { Fragment, memo, useContext } from 'react'
import slugify from 'slugify'
import { Time } from '../Time'
import { RoundImage } from '../RoundImage'
import { Link } from '../Link'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { mixins, theme } from '../../../styles'

export interface IArticleHeaderSign {
  credit?: ArticleHeaderCredit
  hasSponsor: boolean
}

const LinkComponent = ({ url, children, onClick }) => {
  if (url) {
    return (
      <Link url={url} onClick={onClick} target="_blank">
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export function ArticleHeaderSignComponent({
  credit,
  hasSponsor,
}: IArticleHeaderSign): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const {
    authorName,
    picture,
    date,
    updatedAt,
    additionalComment,
    wordings,
    link,
    secondaryAuthors,
  } = credit

  const handleLinkClick = () => {
    hit(
      {
        screen_clickableElementName: `clic-journaliste_${slugify(authorName, { lower: true })}`,
      },
      { isClickEvent: true },
    )
  }

  const getSecondaryAuthors = secondaryAuthors?.map(({ name, link: secondaryLink }, index) => {
    const separator = index === 0 && additionalComment?.length ? ' ' : ', '
    return (
      <Fragment key={index}>
        {separator}
        <LinkComponent url={secondaryLink} key={index} onClick={handleLinkClick}>
          {name}
        </LinkComponent>
      </Fragment>
    )
  })

  return (
    <>
      <div className="ArticleHeaderSign">
        {authorName ? (
          <>
            <div className="ArticleHeaderSign__Author flex items-center">
              {picture ? (
                <LinkComponent url={link} onClick={handleLinkClick}>
                  <RoundImage pictures={picture} />
                </LinkComponent>
              ) : null}
              <span>
                {wordings.authorPrefix ? `${wordings.authorPrefix} ` : ''}
                <LinkComponent url={link} onClick={handleLinkClick}>
                  {authorName}
                </LinkComponent>
                {additionalComment?.length ? ` ${additionalComment}` : ''}
                {getSecondaryAuthors ?? null}
              </span>
            </div>
          </>
        ) : null}
        {(date || updatedAt) && (
          <div className="ArticleHeaderSign__Time">
            <Time publishedAt={date} updatedAt={updatedAt} enableSEO />
          </div>
        )}
      </div>

      <style jsx>{`
        .ArticleHeaderSign__Author {
          color: ${hasSponsor ? theme.cssVars.deepBlue : theme.cssVars.white};
        }
      `}</style>

      <style jsx>{`
        .ArticleHeaderSign {
          margin: 5px 0 10px;
        }

        .ArticleHeaderSign__Time {
          color: ${theme.cssVars.midBlue};
        }

        .ArticleHeaderSign__Author,
        .ArticleHeaderSign__Time {
          font-weight: 400;
          font-size: 13px;
          margin-bottom: 7px;
        }

        .ArticleHeaderSign__Author :global(.RoundImage) {
          width: 34px;
          height: 34px;
          border: 1px solid ${theme.cssVars.white};
          border-top: 1px solid ${theme.cssVars.focusBlue};
          margin-right: 8px;
        }

        .ArticleHeaderSign__Author :global(a) {
          color: ${theme.cssVars.focusBlue};
        }

        .ArticleHeaderSign__Author span:first-child::first-letter {
          text-transform: uppercase;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleHeaderSign__Author {
            font-size: 17px;
          }
          .ArticleHeaderSign__Time {
            font-size: 14px;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .ArticleHeaderSign {
            margin: 5px 0 15px;
          }
        }
      `}</style>
    </>
  )
}
export const ArticleHeaderSign = memo(ArticleHeaderSignComponent)
