import type { Block } from '../../../types/block'
import { mixins } from '../../../styles'

export interface IInterTitle extends Block {
  text: string
}

export function InterTitle({ text, 'data-module': dataModule }: IInterTitle): JSX.Element {
  return (
    <>
      <h2 className="InterTitle" data-module={dataModule}>
        {text}
      </h2>

      <style jsx>{`
        .InterTitle {
          margin: 20px 30px;
          color: var(--color-deepBlue);
          font-family: ${mixins.fonts.article.interTitle.fontFamily};
          font-weight: ${mixins.fonts.article.interTitle.fontWeight};
          font-size: ${mixins.fonts.article.interTitle.fontSize};
          line-height: ${mixins.fonts.article.interTitle.lineHeight};
        }

        @media ${mixins.mediaQuery.desktop} {
          .InterTitle {
            font-size: 38px;
          }
        }
      `}</style>
    </>
  )
}
